var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseCard',{attrs:{"title":"Date string format","subtitle":"To change format options of the displayed date text inside the component.","modalid":"modal-9","modaltitle":"Date string format"},scopedSlots:_vm._u([{key:"highlight",fn:function(){return [_c('pre',{staticClass:"mb-0"},[_vm._v("        "),_c('code',{staticClass:"javascript"},[_vm._v("\n<template>\n  <div>\n    <label for=\"datepicker-dateformat1\">Custom date format</label>\n    <b-form-datepicker\n      id=\"datepicker-dateformat1\"\n      :date-format-options=\"{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }\"\n      locale=\"en\"\n    ></b-form-datepicker>\n\n    <label class=\"mt-3\" for=\"datepicker-dateformat2\">Short date format</label>\n    <b-form-datepicker\n      id=\"datepicker-dateformat2\"\n      :date-format-options=\"{ year: 'numeric', month: 'numeric', day: 'numeric' }\"\n      locale=\"en\"\n    ></b-form-datepicker>\n  </div>\n</template>\n        ")]),_vm._v("\n      ")])]},proxy:true},{key:"comcode",fn:function(){return [_c('label',{attrs:{"for":"datepicker-dateformat1"}},[_vm._v("Custom date format")]),_c('b-form-datepicker',{attrs:{"id":"datepicker-dateformat1","date-format-options":{
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          weekday: 'short',
        },"locale":"en"}}),_c('label',{staticClass:"mt-3",attrs:{"for":"datepicker-dateformat2"}},[_vm._v("Short date format")]),_c('b-form-datepicker',{attrs:{"id":"datepicker-dateformat2","date-format-options":{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        },"locale":"en"}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }